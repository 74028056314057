
import { Component, Prop, Provide, Vue } from "vue-property-decorator";
import { RequestPaging } from "@/utils/util";
import LsForm from "@/components/ls-form.vue";
import LsDialog from "@/components/ls-dialog.vue";
import MaterialSelect from "@/components/material-select/index.vue";

import {
  apiModelEffectLists,
  apiModelEffectAdd,
  apiModelEffectEdit,
  apiModelEffectDelete,
  apiModelEffectFastEdit,
  apiModelDetail,
} from "@/api/model";
@Component({
  components: {
    LsForm,
    LsDialog,
    MaterialSelect,
  },
})
export default class Dialog extends Vue {
  visible = {
    val: false,
  };

  @Prop() value: any;
  @Prop({ default: 0 }) model_id: any;
  @Prop({ default: 0 }) model_item_id: any;
  @Provide("visible")
  visibleVal = this.visible;

  // props
  @Prop({ default: "" }) title!: string; // 弹窗标题
  @Prop({ default: false }) disabled!: boolean; // 是否禁用
  @Prop({ default: false }) async!: boolean; // 是否开启异步关闭
  @Prop({ default: true }) clickModalClose!: boolean; // 点击遮罩层关闭对话窗口
  pager: RequestPaging = new RequestPaging();

  mode = "add";
  formVisible = false;
  formData = [
    {
      type: "input",
      label: "名称",
      key: "name",
      rules: [{ required: true, message: "请输入名称", trigger: "blur" }],
    },

    {
      type: "input",
      label: "定制部位名称",
      key: "design_name",
    },
    {
      type: "images",
      label: "效果图",
      key: "images",
      limit: 5,
      size: "1600px*1600px",
    },
    {
      type: "input",
      label: "排序",
      key: "sort",
    },
    {
      type: "radio",
      label: "状态",
      key: "status",
      list: [
        { label: "启用", value: 1 },
        { label: "禁用", value: 0 },
      ],
    },
  ];

  dataForm: any = {
    id: 0,
    model_id: 0,
    model_item_id: 0,
    name: "",
    design_name: "",
    sort: 0,
    status: 1,
    model_url: "",
    more_spec: 0,
    img_layer: 1,
    is_diy: 0,
    img_suffix: "jpg",
  };

  modelList: any = [
    {
      url: "",
      scale: "6,6,6",
      rotation: "0,0,0",
      position: "0,0,0",
    },
  ];
  images = [];
  specImages: any = [];

  skuDiyData: any = [];

  // methods
  handleEvent(type: "cancel" | "confirm") {
    if (!this.async || type === "cancel") this.close();
    if (type === "confirm") {
    }
  }

  getList() {
    this.pager.request({
      callback: apiModelEffectLists,
      params: { model_id: this.model_id, model_item_id: this.model_item_id },
    });
  }

  addModel(index = 0) {
    if (this.dataForm.more_spec == 1) {
      this.skuDiyData[index]["model"].push({
        url: "",
        scale: "6,6,6",
        rotation: "0,0,0",
        position: "0,0,0",
      });
    } else {
      this.modelList.push({
        url: "",
        scale: "6,6,6",
        rotation: "0,0,0",
        position: "0,0,0",
      });
    }
  }

  onTrigger() {
    if (this.disabled) return;
    this.open();
  }

  close() {
    this.visible.val = false;
    this.$emit("close");
  }

  open() {
    this.visible.val = true;

    this.$emit("open");
    this.getList();
  }

  async handleAdd() {
    this.mode = "add";
    this.modelList = [
      {
        url: "",
        scale: "6,6,6",
        rotation: "0,0,0",
        position: "0,0,0",
      },
    ];
    this.dataForm = {
      id: 0,
      model_id: 0,
      model_item_id: 0,
      name: "",
      design_name: "",
      sort: 0,
      status: 1,
      model_url: "",
      more_spec: 0,
      is_diy: 0,
      img_suffix: "jpg",
    };
    this.specImages = [];
    await this.getSpecValue();
    this.formVisible = true;
  }

  async handleEdit(row: any) {
    this.dataForm = row;
    delete this.dataForm.create_time;
    delete this.dataForm.update_time;
    delete this.dataForm.delete_time;
    delete this.dataForm.model_name;
    if (this.dataForm.more_spec == 0) {
      this.images =
        this.dataForm.images != "" ? this.dataForm.images.split(",") : [];
      this.modelList = JSON.parse(this.dataForm.model_url);
    } else {
      this.skuDiyData = JSON.parse(this.dataForm.sku_diy_data);
    }
    this.mode = "edit";
    if (!this.dataForm.sku_diy_data) {
      await this.getSpecValue();
    }
    this.formVisible = true;
  }

  getSpecValue() {
    return new Promise((resolve, reject) => {
      apiModelDetail({ id: this.model_id }).then((res) => {
        let spec_list = res.spec_value_list;
        let skuDiyDataCopy: any = [];
        for (let specIndex in spec_list) {
          let spec = spec_list[specIndex];
          skuDiyDataCopy[specIndex] = {
            name: spec.spec_value_str,
            images: [],
            img_layer: 1,
            model: [
              {
                url: "",
                scale: "6,6,6",
                rotation: "0,0,0",
                position: "0,0,0",
              },
            ],
          };
        }
        this.skuDiyData = skuDiyDataCopy;
        resolve(true);
      });
    });
  }

  handleChangeValue(id: number, value: number, key: string) {
    apiModelEffectFastEdit({ id: Number(id), [key]: value }).then((res) => {
      this.$message.success("success");
      this.getList();
    });
  }

  handleSubmit() {
    this.dataForm.model_id = this.model_id;
    this.dataForm.model_item_id = this.model_item_id;

    if (this.dataForm.more_spec == 0) {
      this.dataForm.model_url = JSON.stringify(this.modelList);
      this.dataForm.images = this.images.join(",");
    } else {
      this.dataForm.sku_diy_data = JSON.stringify(this.skuDiyData);
    }

    if (this.mode == "add") {
      apiModelEffectAdd({ ...this.dataForm }).then((res) => {
        this.getList();
        this.formVisible = false;
      });
    } else {
      apiModelEffectEdit({ ...this.dataForm }).then((res) => {
        this.getList();
        this.formVisible = false;
      });
    }
  }

  handleDelete(id: number) {
    apiModelEffectDelete({ id: id }).then((res) => {
      this.getList();
    });
  }

  created() {}
}
