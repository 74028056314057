
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Editor from '@/components/editor.vue'
import MaterialSelect from '@/components/material-select/index.vue'

@Component({
  components: {
    Editor,
    MaterialSelect
  }
})
export default class Form extends Vue {
  @Prop({ default: () => [] }) formData!: [] | any;
  @Prop({ default: () => ({}) }) dataForm: any; //
  @Prop({ default: () => ({}) }) rules: any; //
  @Prop({ default: '100px' }) labelwidth!: string; //
  $refs!: { formRef: any };
  scrollToErrorField () {
    this.$nextTick(() => {
      const errorItems = this.$el.querySelectorAll('.is-error')
      if (errorItems.length > 0) {
        errorItems[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    })
  }

  submitForm () {
    this.$refs.formRef.validate((valid: boolean) => {
      if (valid) {
        this.$emit('confirm', this.dataForm)
      } else {
        this.scrollToErrorField()
        return false
      }
    })
  }
}
